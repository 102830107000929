import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65f95717"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseOutlined = _resolveComponent("CloseOutlined")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.breadcrumbWrapper, _ctx.className]),
    style: _normalizeStyle(_ctx.outSideStyle)
  }, [
    (_ctx.showScrollContainer)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "scroll-container",
          style: _normalizeStyle(_ctx.scrollContainerStyle)
        }, [
          _createElementVNode("div", {
            class: "scroll-box",
            style: _normalizeStyle(_ctx.scrollBoxLineHeight)
          }, _toDisplayString(_ctx.scrollText), 5),
          _createElementVNode("div", {
            class: "scroll-closed",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeScrollContainer && _ctx.closeScrollContainer(...args)))
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_CloseOutlined)
            ])
          ])
        ], 4))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.breadCrumb),
      style: _normalizeStyle(_ctx.scrollBreadCrumbStyle)
    }, [
      _createVNode(_component_a_breadcrumb, { separator: ">" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.crumbLists, (item, index) => {
            return (_openBlock(), _createBlock(_component_a_breadcrumb_item, { key: index }, {
              default: _withCtx(() => [
                (item.mnuuri)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: item.mnuuri
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$i18n.locale === "zh" ? item.mnunmcn : item.mnunmen), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.$i18n.locale === "zh" ? item.mnunmcn : item.mnunmen), 1)
                    ], 64))
              ]),
              _: 2
            }, 1024))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSource, (item, index) => {
            return (_openBlock(), _createBlock(_component_a_breadcrumb_item, { key: index }, {
              default: _withCtx(() => [
                (item.path)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: item.path
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ], 64))
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ], 6),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 6))
}