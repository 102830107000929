import store from '@/store'
import router from '@/router'
import { getUrlParams } from '@/utils/index';
const Authorization = sessionStorage.getItem('Authorization');

// 获取地址连接上的code
const permissionCode = getUrlParams().code;
export default function permission(): void {
    permissionCode && sessionStorage.setItem('CODE', permissionCode)
    // 获取地址连接上的code后去除url上的参数
    // 重定向到地址链接对应域名地址
    const protocolStr = window.location.protocol;
    const hostStr = window.location.host;
    const path = localStorage.getItem('userUrl') ?? '';
    const urlStrHome = protocolStr + '//' + hostStr + '/#/' + path;
    history.replaceState(null, '', urlStrHome);
    
    if (process.env.VUE_APP_SSO === 'yes') {
        if (permissionCode && !Authorization) {

            // 有code无token
            // 用code请求token
            // 获取Authorization(token)和用户信息
            store.dispatch('authAndUser', {
                code: permissionCode
            }).catch(() => {/**/});
        } else {
            // 一下三种情况都应访问currentuser接口
            // 有code有token--拿用户信息
            // 无code有token--重定向到认证页面
            // 无code无token--重定向到认证页面
            store.dispatch('updateUser').catch(() => {/**/});
        }
    } else {
        if (!Authorization) {
            router.push('/login');
        } else {
            store.dispatch('updateUser').catch(() => {/**/});
        }
    }
}