
import { defineComponent, ref, reactive, toRefs, onMounted, h } from 'vue'
import { PGProps, PageOptionProps, LegalEntity, RoleProps, SearchParamsProps } from './types'
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue'
import { addDataKey, getPermissions } from "@/utils"
import useSearch from '@/hooks/useSearch'
import useAddEditRole from '@/hooks/useAddEditRole'
import { rolesFindall, PGfindalllist, rolesChangestatus, getLegalEntityOptions } from '@/API/user'
import useTableHeight from "@/hooks/useTableHeight";
// 表格的列
const columns = [
    {
        title: 'No.',
        dataIndex: 'key',
        key: 'key',
        width: '6%'
    },
    {
        dataIndex: 'rolnmcn',
        key: 'rolnmcn',
        // 这里customRender: 'name',name和上面#name一致
        slots: { title: 'customName', customRender: 'name' },
        width: '12%'
    },
    {
        dataIndex: 'roltpnm',
        key: 'roltpnm',
        slots: { title: "customType" },
        width: '10%'
    },
    {
        dataIndex: 'privgrpnmen',
        key: 'privgrpnmen',
        // 这里customRender: 'name',name和上面#name一致
        slots: { title: 'customPG', customRender: 'grpname' },
        width: '15%'
    },
    {
        dataIndex: 'roldsc',
        key: 'roldsc',
        slots: { title: 'customDsc', customRender: 'description' },
    },
    {
        key: 'operation',
        slots: { title: 'customOperation', customRender: 'operation' },
        width: '25%'
    }
]

export default defineComponent({
    components: {
    },
    setup () {
        const store = useStore()
        const innerHeight = ref(window.innerHeight)
        // 表格的数据
        const searchPG = ref<PGProps[]>()

        const legalEntityOptions = ref<LegalEntity[]>()
        const getPermission = getPermissions()
        // 分页配置
        const pageOptions = reactive(
            {
                pageSize: 0,
                current: 0,
                total: 1,
                pageSizeOptions: ['10', '20', '30', '40'],
                showSizeChanger: true,
                showQuickJumper: true,
            }
        )
        const { tableHeight } = useTableHeight(true, '.ant-table-pagination');
        // 表单的数据
        // const prgOptions = ref<PrgProps[]>([])
        const {enName, cnName, userType, searchSubmit, privilegeGroup, searchChange, boolSearch} = useSearch(pageOptions, rolesFindall, 'privgrpid')
        const rolesFindallFn = (params: SearchParamsProps, current: number): void => {
            rolesFindall({ params: params }).then((res): void => {
                privilegeGroup.value = addDataKey(res.content);
                pageOptions.pageSize = res.size;
                pageOptions.total = res.totalElements;
                pageOptions.current = current;
            })
        }
        // 表格
        // 表格请求数据, 初始表格数据
        const initTableData = (pageIndex = 0, pageSize = 10) => {
            const params = {
                pageIndex: pageIndex,
                pageSize
            }
            rolesFindallFn(params, pageIndex + 1)
            PGfindalllist().then(res => {
                searchPG.value = addDataKey(res)
            })
        }
        // 表格选择页码页面
        const changePage = (pagination: PageOptionProps) => {
            if (!userType.value && !enName.value && !cnName.value) {
                initTableData(pagination.current - 1, pagination.pageSize)
            } else {
                const params = {
                    privgrpid: userType.value,
                    name: store.state.lang === 'en' ? enName.value.trim() : cnName.value.trim(),
                    pageIndex: pagination.current - 1,
                    pageSize: pagination.pageSize
                }
                rolesFindallFn(params, pagination.current)
            }
        }
        const { handleEdit, handleCancel, submitAddPG, ruleFormRef, grptpChange, handleAddPG, visible, isAdd, modalTitle, formData, grpChange, prgOptions} = useAddEditRole()
        
        // 删除
        const delVisible = ref(false)
        const handleDisable = (id: RoleProps) => {
            Modal.confirm({
                class: 'my-confirm',
                content: h('div', {}, [
                    h('i',{ 'class': 'font_family icon-shezhi confirm-h'}, ''),
                    h('h1',{ 'style': 'font-size: 18px;'} , 'Are you sure to change the status of role?'),
                ]),
                onOk() {
                    return new Promise((resolve, reject) => {
                        const params = {
                            rolid: id
                        }
                        //接口
                        rolesChangestatus(params).then((res: any) => {
                            let nowState = ''
                            if (res.state) {
                                nowState = 'true'
                            }  else {
                                nowState = 'false'
                            }
                            sessionStorage.setItem("state", nowState)
                            window.location.reload()
                        })
                        // console.log(resolve, reject)
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                        // 更新数据
                    }).catch(() => console.log('Oops errors!'));
                },
                onCancel() {
                    console.log('cancel')
                },
            })
        }

        const getLegalEntity = () => {
            getLegalEntityOptions().then((res) => {
                legalEntityOptions.value = res
            })
        }
        onMounted(() => {
            getLegalEntity()
            initTableData()
        })
        return {
            prgOptions,
            ruleFormRef,
            isAdd,
            modalTitle,
            innerHeight,
            columns,
            // 表格数据
            privilegeGroup,
            pageOptions,
            changePage,
            // 添加
            handleAddPG,
            submitAddPG,
            handleCancel,
            grptpChange,
            // 删除
            handleDisable,
            delVisible,
            enName,
            cnName,
            userType,
            visible,
            searchPG,
            ...toRefs(formData),
            searchSubmit,
            searchChange,
            boolSearch,
            handleEdit,
            legalEntityOptions,
            grpChange,
            getPermission,
            tableHeight,
        }
    },
})
