
  import { defineComponent, PropType, onMounted, ref, computed } from "vue";
  import { useStore } from "vuex";
  import { TreeProps } from "@/views/User/types";
  import { useRoute } from "vue-router";
  import { CloseOutlined } from "@ant-design/icons-vue";
  import { getNowActiveLampData } from "@/API/payment";
import store from "@/store";
  interface Breadcrumb {
  name: string;
  path: string;
  }
  export default defineComponent({
  name: "Breadcrumb",
  props: {
    dataSource: {
      type: Array as PropType<Breadcrumb[]>,
      required: true,
      default() {
        return [];
      },
    },
    className: {
      type: String,
    },
    scrollHeight: {
      type: Number,
      default: -1,
    },
    lineHeight: {
      type: Number,
      default: -1,
    },
    paddingBottom: {
      type: Number,
      default: -1,
    },
    outSideHeight: {
      type: Number,
      default: -1,
    }
  },
  components: {
    CloseOutlined
  },
  setup(props) {
    const { commit, state } = useStore();
    const route = useRoute();
    const crumbLists = ref<
      { mnunmcn: string; mnunmen: string; mnuuri: string }[]
    >([]);
  
    // 找到树形菜单，当前mnuid的，所有父级
    const familyTree = (arrTree: TreeProps[], id: string) => {
      const temp: TreeProps[] = [];
      const forFn = (arr: TreeProps[], id: string | undefined) => {
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i];
          if (item.mnuid === id) {
            temp.push(item);
            forFn(arrTree, item.parentid); // 注意这里是传入的tree，不要写成data了，不然遍历的时候一直都是node.children,不是从最顶层开始遍历的
            break;
          } else {
            if (item.children) {
              forFn(item.children, id);
            }
          }
        }
      };
      forFn(arrTree, id);
      return temp;
    };
  
    // 找到对应需要的参数，菜单和面包屑需要要的
    const findURL = (arr: TreeProps[], url: string): any => {
      if (arr === null) return [];
      let openKey: string[] = [];
      const selectedKey: string[] = [];
      for (const obj of arr) {
        if (obj.mnuuri === url) {
          if (obj.parentid !== "0") {
            openKey = [
              ...familyTree(state.user.menulist, obj.mnuid).map(
                (item: TreeProps) => item.mnuid
              ),
            ];
          } else {
            openKey.push(obj.mnuid);
          }
          // 当前路由需要,当前菜单选中项
          selectedKey.push(obj.mnuid);
          // 当前页面时面包屑，当时的页面时
          // 更新vuex
          commit("updateSideNavKeys", {
            openKeys: openKey,
            selectedKeys: selectedKey,
          });
          return [obj];
        }
        const ret = findURL(obj.children, url);
        if (ret.length > 0) return ret;
      }
      return [];
    };
  
    // const breadcrumbWrapper = ref('breadcrumb-wrapper bg-fff');

    const scrollText = ref('');
    const currentScrollEndTime = ref('');
    const showScrollContainer = computed(() => store.state.showScrollContent && !store.state.userClosedScrollContent);
    const breadcrumbWrapper = computed(() => showScrollContainer.value ? 'breadcrumb-wrapper-scroll bg-fff' : 'breadcrumb-wrapper bg-fff');
    const breadCrumb = computed(() => showScrollContainer.value ? 'breadcrumb breadcrumb-scroll d-f align-c' : 'breadcrumb d-f align-c');
    const scrollContainerStyle = computed(() => {
      return {
        height: (props as any).scrollHeight > 0 ? `${(props as any).scrollHeight}px` : '28px',
      };
    });


    const scrollBreadCrumbStyle = computed(() => {
      return (props as any).paddingBottom > 0 && showScrollContainer.value ? { 'padding-bottom' : `${(props as any).paddingBottom}px`} : {};
    });

    const outSideStyle = computed(() => {
      return (props as any).outSideHeight > 0 ? { height : `${(props as any).outSideHeight}px`} : {};
    });

    const scrollBoxLineHeight = computed(() => {
      return (props as any).lineHeight > 0 ? { 'line-height' : `${(props as any).lineHeight}px`} : {};
    });

    console.log("bofore mounted state showScrollContent value is:" + state.showScrollContent);
    console.log("bofore mounted state userClosedScrollContent value is:" + state.userClosedScrollContent);

    onMounted(async() => {
      // // 直接从复制URL的情况: 如果是菜单里有的路由，没有问题，没有的呢
      if (
        findURL(state.user.menulist, route.path).length < 1 &&
        state.sideNavKeys.selectedKeys.length < 1
      ) {
        commit("updateSideNavKeys", {
          openKeys: [],
          selectedKeys: [],
        });
      }
      // 针对从正常情况进来，菜单和导航
      crumbLists.value = familyTree(
        state.user.menulist,
        state.sideNavKeys.selectedKeys[0]
      ).reverse();
      if (route.fullPath.startsWith("/faq")) {
        crumbLists.value = [];
      }

      const lampData = await getNowActiveLampData();
      if (lampData && lampData.contents) {
        scrollText.value = lampData.contents;
        const endTime = new Date(lampData.endTime).getTime();
        const currentTime = Date.now();
        currentScrollEndTime.value = lampData.endTime;
        console.log("api get endtime:" + lampData.endTime);
        if (currentTime < endTime) {
          store.commit("updateShowScrollContent", true);
        }
        const lastScrollEndTime = store.state.lastScrollEndTime;
        console.log("lastScrollEndTime:" + lastScrollEndTime)
        if (lastScrollEndTime && store.state.userClosedScrollContent) {
          const lastScrollEndTimeDate = new Date(lastScrollEndTime).getTime();
          if (lastScrollEndTimeDate < endTime) {
            store.commit("updateUserClosedScrollContent", false);
          }
        }
      } else {
        store.commit("updateShowScrollContent", false);
      }

      console.log("after mounted state showScrollContent value is:" + state.showScrollContent);
      console.log("after mounted state userClosedScrollContent value is:" + state.userClosedScrollContent);
    });



    const closeScrollContainer = () => {
      console.log('close scroll container');
      // breadcrumbWrapper.value = 'breadcrumb-wrapper bg-fff';
      // breadCrumb.value = 'breadcrumb d-f align-c';
      store.commit("updateShowScrollContent", false);
      store.commit("updateUserClosedScrollContent", true)
      store.commit("updateLastScrollEndTime", currentScrollEndTime.value)
    };


    return {
      crumbLists,
      showScrollContainer,
      closeScrollContainer,
      breadcrumbWrapper,
      breadCrumb,
      scrollContainerStyle,
      scrollText,
      scrollBreadCrumbStyle,
      outSideStyle,
      scrollBoxLineHeight
    };
  },
  });
  