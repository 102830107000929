import { RouteRecordRaw } from "vue-router";

export const pmtRouters: Array<RouteRecordRaw> = [
  {
    path: "/paymentMBAFC",
    name: "Payment MBAFC",
    component: () => import("@/views/Payment/FlRt/index.vue"),
  },
  {
    path: "/paymentApproveMgmt",
    name: "paymentApproveMgmtIndex",
    component: () => import("@/views/Payment/Mgmt/index.vue"),
    children: [
      {
        path: "",
        name: "paymentApproveMgmt",
        component: () =>
          import("@/views/Payment/Mgmt/PendingApproval/index.vue"),
      },
      {
        path: "paymentTracking",
        name: "Payment Tracking",
        component: () =>
          import("@/views/Payment/Mgmt/PaymentTracking/index.vue"),
      },
      {
        path: "dealerPayoutOverview",
        name: "Payment Approve Overview",
        component: () =>
          import(
            "@/views/Payment/Mgmt/PendingApproval/PayoutRound/index.vue"
          ),
      },
      {
        path: "dealerPayoutTrackingOverview",
        name: "Payment Tracking Overview",
        component: () =>
          import(
            "@/views/Payment/Mgmt/PaymentTracking/PayoutRound/index.vue"
          ),
      },
    ],
  },
  {
    path: "/paymentApproveMBAFC",
    name: "paymentApproveMBAFC",
    component: () => import("@/views/Payment/MbafcAndMblc/index.vue"),
    children: [
      {
        path: "",
        name: "paymentApproveMBAFC",
        component: () =>
          import("@/views/Payment/MbafcAndMblc/approveAFCandLC.vue"),
      },
      {
        path: "paymentTrackingMBAFC",
        name: "paymentTrackingMBAFC",
        component: () =>
          import("@/views/Payment/MbafcAndMblc/approveAFCandLC.vue"),
      },
    ],
  },
  {
    path: "/paymentApproveMBLC",
    name: "paymentApproveMBLC",
    component: () => import("@/views/Payment/MbafcAndMblc/index.vue"),
    children: [
      {
        path: "",
        name: "paymentApproveMBLC",
        component: () =>
          import("@/views/Payment/MbafcAndMblc/approveAFCandLC.vue"),
      },
      {
        path: "paymentTrackingMBLC",
        name: "paymentTrackingMBLC",
        component: () =>
          import("@/views/Payment/MbafcAndMblc/approveAFCandLC.vue"),
      },
    ],
  },
  // BU
  // Dealer + F&L
  // {
  //   path: "/paymentBuManagement",
  //   name: "BU Payment Management",
  //   component: () => import("@/views/Payment/BU/index.vue"),
  //   children: [
  //     {
  //       path: "",
  //       name: "Payment Bu Management Dealer",
  //       component: () =>
  //         import("@/views/Payment/BU/Dealer/index.vue"),
  //     },
  //     {
  //       path: "/paymentBuManagement/FAndL",
  //       name: "Payment Bu Management F&L",
  //       component: () =>
  //         import("@/views/Payment/BU/F&L/index.vue"),
  //     },
  //   ],
  // },
  // BU Payment Management 替换为 BU SI Payout Application
  {
    path: "/buSiPayoutApplication",
    name: "BU SI Payout Application",
    component: () => import("@/views/Payment/BU/index.vue"),
    children: [
      {
        path: "",
        name: "Payment Bu Management Dealer",
        component: () =>
          import("@/views/Payment/BU/Dealer/index.vue"),
      },
      {
        path: "/paymentBuManagement/FAndL",
        name: "Payment Bu Management F&L",
        component: () =>
          import("@/views/Payment/BU/F&L/index.vue"),
      },
    ],
  },
  // Delear No Overview
  {
    path: "/paymentBuManagement/dealerNoOverview",
    name: "Payment Delear No Overview",
    component: () =>
      import("@/views/Payment/BU/DealerNoOverview/index.vue"),
  },
  // Dealer Setting
  {
    path: "/paymentBuManagement/dealerSetting",
    name: "Payment Dealer Setting",
    component: () =>
      import("@/views/Payment/BU/DealerSetting/index.vue"),
  },
  // Create Payout Batch
  {
    path: "/paymentBuManagement/createPayoutBatch",
    name: "Payment Create Payout Batch",
    component: () =>
      import("@/views/Payment/BU/CreatePayoutBatch/index.vue"),
  },
  // Sales
  // {
  //   path: "/paymentSales",
  //   name: "Sales Payment Management",
  //   component: () =>
  //     import("@/views/Payment/Sales/index.vue"),
  //   children: [
  //     {
  //       path: "",
  //       name: "SalesPaymentManagement",
  //       component: () =>
  //         import("@/views/Payment/Sales/SalesPaymentManagement/index.vue"),
  //       meta: {
  //         title: 'Sales Payment Management',
  //         showInTab: true,
  //       }
  //     },
  //     {
  //       path: "A3Tracking",
  //       name: "A3Tracking",
  //       component: () =>
  //         import("@/views/Payment/PaymentTracking/A3Tracking/index.vue"),
  //       meta: {
  //         title: 'A3 Tracking',
  //         showInTab: true,
  //       }
  //     }
  //   ]
  // },
  // 替换 Sales Payment Management 为 Entity SI Payout Application
  {
    path: "/entitySiPayoutApplication",
    name: "Entity SI Payout Application",
    component: () =>
      import("@/views/Payment/EntitySiPayout/index.vue"),
  },
  {
    path:'/demoCDview',
    name:'Demo Car Deduction View',
    component: () => import ('@/views/Payment/EntitySiPayout/Demo/demoCDView.vue')
  },
  // payment tracking
  {
    path: "/paymentTracking",
    name: "PaymentTracking",
    component: () => import("@/views/Payment/PaymentTracking/index.vue"),
    children: [
      {
        path: "",
        name: "Dealer Payout Overview",
        meta: {
          title: 'Dealer Payout Overview',
          showInTab: true,
          permission: {path: '/paymentTracking', privcd: 'PT0001'}
        },
        component: () =>
          import("@/views/Payment/PaymentTracking/DealerPayout/index.vue"),
      },
      {
        path: "dealerStatus",
        name: "Dealer Status",
        meta: {
          title: 'Dealer Status Tracking',
          showInTab: true,
          permission: {path: '/paymentTracking', privcd: 'PT0002'}
        },
        component: () =>
          import("@/views/Payment/PaymentTracking/DealerStatus/index.vue"),
      },
      {
        path: "a3tracking",
        name: "A3 Tracking",
        meta: {
          title: 'A3 Tracking',
          showInTab: true,
          permission: {path: '/paymentTracking', privcd: 'PT0003'}
        },
        component: () =>
          import("@/views/Payment/PaymentTracking/A3Tracking/index.vue"),
      },
    ],
  },
  {
    path: "/historicalRedInvoice",
    name: "Historical Red Invoice Notice Readiness",
    component: () =>
      import("@/views/Payment/PaymentTracking/HistoricalRedInvoice/index.vue")
  },
  {
    path: "/paymentSales/dealerVendorCodeSet",
    name: "Dealer Vendor CodeSet",
    component: () =>
      import("@/views/Payment/EntitySiPayout/Vendor/index.vue"),
  },
  {
    path: "/paymentSales/vehicleNameSet",
    name: "Vehicle Name Set",
    component: () =>
      import("@/views/Payment/EntitySiPayout/Vehicle/index.vue"),
  },
  {
    path: "/paymentSales/voucherAttachmentSet",
    name: "Voucher Attachment Set",
    component: () =>
      import("@/views/Payment/EntitySiPayout/Voucher/index.vue"),
  },
  {
    path: "/paymentSales/DcnTemplateSet",
    name: "DcnTemplateSet",
    component: () =>
      import("@/views/Payment/EntitySiPayout/DcnTemplateSet/index.vue"),
  },
  {
    path: "/paymentSales/DealerBreakdownReport",
    name: "DealerBreakdownReport",
    component: () =>
      import("@/views/Payment/EntitySiPayout/DealerBreakdownReport/index.vue")
  },
  {
    path: "/DgrcAccounting",
    name: "DgrcAccounting",
    component: () =>
      import("@/views/Payment/DgrcAccounting/index.vue"),
  },
  {
    path: "/DealerBreakdown",
    name: "DealerBreakdown",
    component: () =>
      import("@/views/Payment/DealerBreakdown/index.vue"),
  },
  {
    path: "/DealerBreakdown/Detail",
    name: "DealerBreakdownDetail",
    component: () =>
      import("@/views/Payment/DealerBreakdown/detail/index.vue"),
  },
  {
    path: "/paymentBBAC",
    name: "BBAC Payment Management",
    component: () =>
      import("@/views/Payment/BBAC/index.vue"),
  },
  {  
    path: "/paymentControlling",
    name: "Controlling Payment Management",
    component: () =>
      import("@/views/Payment/Controlling/Dealer/index.vue"),
    children: [
      {
        path: '',
        name: 'ControllingDealerPayoutOverview',
        component: () => import('@/views/Payment/Controlling/Dealer/DealerPaymentOverview/index.vue'),
        meta: {
          title: 'Dealer Payout Overview',
          showInTab: true,
          permission: {path: '/paymentControlling', privcd: 'CPM0001'}
        }
      },
      {
        path: 'dealerRoundSummary/:payoutRound',
        name: 'ControllingDealerRoundSummary',
        component: () => import("@/views/Payment/Controlling/Dealer/DealerPaymentOverview/DealerRoundSummary/index.vue"),
        meta: {
          activeTabName: 'ControllingDealerPayoutOverview'
        }
      },
      {
        path: 'dealerStatusTracking',
        name: 'dealerStatusTracking',
        component: () => import('@/views/Payment/Controlling/Dealer/DealerStatusTracking/index.vue'),
        meta: {
          title: 'Dealer Status Tracking',
          showInTab: true,
          permission: {path: '/paymentControlling', privcd: 'CPM0002'}
        }
      }
    ]
  },
  {
    path: '/paymentControlling/FLPayoutOverview',
    name: 'ControllingFLPayoutOverview',
    component: () => import('@/views/Payment/Controlling/Fl/index.vue'),
  },
  // mobile
  {
    path: "/paymentMobile/pendingApproval",
    name: "Mobile Pending Approval",
    meta: {
      isMobile: true
    },
    component: () =>
      import("@/views/Payment/Mobile/PendingApproval/index.vue"),
  },
  {
    path: '/paymentMobile/PayoutCodeDealer',
    name: 'MobilePayoutCodeDealer',
    meta: {
      isMobile: true
    },
    component: () =>
      import('@/views/Payment/Mobile/PendingApproval/payoutCodeDealer.vue'),
  },
  {
    path: '/paymentMobile/PayoutCodeAFCLC',
    name: 'MobilePayoutCodeAFCLC',
    meta: {
      isMobile: true
    },
    component: () =>
      import('@/views/Payment/Mobile/PendingApproval/payoutCodeAFCLC.vue'),
  },
    {
        path: '/paymentApprove/paymentpreparation',
        name: 'Payment Preparation',
        component: () => import('@/views/Payment/paymentPreparation/index.vue')
    },
    {
        path: '/paymentApprove/paymentPreparationDetail',
        name: 'Payment Preparation Detail',
        component: () => import('@/views/Payment/paymentPreparationDetail/index.vue')
    },
    {
        path: '/paymentApprove/paymentPreparationDetail/detail',
        name: 'Payment Preparation Detail Detail',
        component: () =>
            import('@/views/Payment/paymentPreparationDetail/Detail/index.vue'),
    }
]
