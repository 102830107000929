import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import { CategoryProps, APIDataProps, ColumnsProps } from '@/types';
import { TreeProps, RoleProps } from '@/views/User/types';
import approve from '@/store/modules/approve';
import claim from '@/store/modules/claim';
import payment from '@/store/modules/payment';
import approvalChain from '@/store/modules/approvalChain';
import spotChecking from '@/store/modules/spotChecking';
import finance from './modules/finance';
import localRegistration from '@/store/modules/localRegistration';
import { isMobile } from '@/utils/index';
import { CustomerTypeProps, getDesignSetting } from '@/API/program';
import { getToken, findCurrentUser, ssoLogout } from '@/API/user';
import { message } from 'ant-design-vue';
import i18n from '@/locales/index';
import salesReport from './modules/salesReport';
const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  supportCircular: isMobile()
});

// 兼容性Category表格的数据
export interface TableCellProps {
  id: string;
  key: string;
  isVisble: boolean;

  [key: string]: any;
}

export interface SelfProps {
  offerTypeKey: string;
  offerTypeName: string;
  table: CulTable[];
}

export interface OtherProps {
  category: [];
  program: [];
  offer: SelfProps[];
}

export interface CulTable extends APIDataProps {
  columns: ColumnsProps[];
}

export interface BuDataProps {
  orgcode: string;
  orgid: string;
  orgnmcn: string;
  orgnmen: string;
}

export interface BuProps {
  id: number;
  nameCn: string;
  nameEn: string;
}

export interface BuTreeProps extends BuProps {
  children: BuTreeProps[];
}

export interface EntityProps {
  id: number;
  entityCode: string;
  entityName: string;
  entityType: string;
}

export interface LegalEntityProps {
  id: number;
  parentId: number | null;
  entityCode: string;
  entityName: string;
  entityNameEn: string;
  entityType: string;
}

export interface OrganizationProps {
  id: string;
  parentId: string | null;
  entityCode: string;
  entityName: string;
  entityNameEn: string;
  entityType: string;
}

export interface UserProps {
  email?: string;
  fulnm: string;
  orgid?: string;
  orgnm?: string;
  state?: boolean;
  menulist: TreeProps[];
  userroles: RoleProps[];
  usrid?: string;
  usrnm?: string;
  usrtp?: number;
  usrtpnm?: string;
  bu?: BuDataProps;
  buList?: BuProps[];
  buTreeList?: BuTreeProps[];
  entityList?: EntityProps[];
  legalEntity?: LegalEntityProps;
  organization?: OrganizationProps;
}

export interface GlobalDataProps {
  user: UserProps;
  lang: string;
  scrollHeight: number;
  spinning: boolean;
  pageSpinning: boolean;
  Authorization: string;
  maintain: boolean;
  operable: boolean;
  // program
  pgmData: any;
  pgmAny: any;
  isPolling: boolean;
  pgmCategory: CategoryProps[];
  pgmGroup: any;
  pgmGroupCategorys: {
    groupName: string;
    offerType: CategoryProps[];
  }[];
  pgmCombinability: {
    programId: string;
    internal: {
      left: SelfProps[];
      right: [];
    };
    other: OtherProps;
    offerOther: Record<string, unknown>;
    target: Record<string, unknown>;
    specialUsage: [];
    faCrossTerm: {
      from?: string;
      to?: string;
      checked: boolean;
    };
  };
  //侧边栏是否收起
  sideNavCollapsed: boolean;
  sideNavKeys: {
    openKeys: string[];
    selectedKeys: string[];
  };
  pgmInsetCol: Record<string, unknown>;
  loginForward?: string;
  customerSelected: CustomerTypeProps[];
  make: string;
  keepAliveComponentNames: string[];
  programHomeParams: any;
  combinabilityResetKey: any;
  showScrollContent:boolean;
  userClosedScrollContent:boolean;
  lastScrollEndTime:string
}

const store = createStore<GlobalDataProps>({
  state: {
    user: {
      fulnm: '',
      menulist: [],
      userroles: []
    },
    Authorization: '',
    lang: 'en',
    scrollHeight: 301,
    spinning: false,
    pageSpinning: false,
    maintain: true,
    operable: true,
    pgmData: {},
    // 表格插入列
    pgmAny: {
      tableId: ''
    },
    //轮询
    isPolling: true,
    // 拼接在一起的比如fa, overall,下面的组循环显示用
    pgmCategory: [],
    //
    pgmGroup: [],
    // base info循环用
    pgmGroupCategorys: [],
    // pgm Combinability setting
    pgmCombinability: {
      programId: '',
      internal: {
        left: [],
        right: []
      },
      other: {
        category: [],
        program: [],
        offer: []
      },
      offerOther: {},
      target: {},
      specialUsage: [],
      faCrossTerm: {
        checked: false
      }
    },
    sideNavCollapsed: false,
    sideNavKeys: {
      openKeys: [],
      selectedKeys: []
    },
    // 插入列的table数据
    pgmInsetCol: {},
    customerSelected: [],
    loginForward: '/',
    make: '',
    keepAliveComponentNames: [],
    programHomeParams: null,
    combinabilityResetKey: '',
    showScrollContent:false,
    userClosedScrollContent:false,
    lastScrollEndTime:''
  },
  getters: {
    isDealer(state) {
      return state.user.legalEntity?.entityCode === 'Dealer' || state.user.legalEntity?.entityCode === 'Dealer Group';
    }
  },
  mutations: {
    updateLang(state, rawData) {
      state.lang = rawData;
    },
    updateIsPolling(state, rawData) {
      state.isPolling = rawData;
    },
    updatedHeight(state, screenWidth) {
      if (screenWidth < 1280) {
        state.scrollHeight = 160;
      }
      if (screenWidth >= 1280) {
        state.scrollHeight = 220;
      }
      if (screenWidth >= 1440) {
        state.scrollHeight = 251;
        // state.scrollHeight = 380
      }
      if (screenWidth >= 1680) {
        state.scrollHeight = 460;
      }
      if (screenWidth >= 1920) {
        state.scrollHeight = 470;
        // state.scrollHeight = 610
      }
    },
    updateUser(state, rawData) {
      state.user = rawData;
    },
    updateAuthorization(state, rawData) {
      sessionStorage.setItem('Authorization', rawData);
      state.Authorization = rawData;
    },
    updateSpinning(state, rawData) {
      state.spinning = rawData;
    },
    updatePageSpinning(state, rawData) {
      state.pageSpinning = rawData;
    },
    updateMaintain(state, rawData) {
      state.maintain = rawData;
    },
    // 控制addoffer是否可操作
    updateOperable(state, rawData) {
      state.operable = rawData;
    },
    updatePgmData(state, rawData) {
      state.pgmData = rawData;
    },
    updatePgmDataProgramCode(state, rawData) {
      state.pgmData.programCode = rawData;
    },
    updatepgmAny(state, rawData) {
      state.pgmAny.tableId = rawData;
    },
    updatePgmCategory(state, rawData) {
      state.pgmCategory = rawData;
    },
    updatePgmGroup(state, rawData) {
      state.pgmGroup = rawData;
    },
    updatePgmGroupCategorys(state, rawData) {
      state.pgmGroupCategorys = rawData;
    },
    updatePgmCombinability(state, rawData) {
      Object.assign(state.pgmCombinability, rawData);
      // state.pgmCombinability = rawData;
    },
    updatePgmCombinabilityspecialUsage(state, rawData) {
      state.pgmCombinability.specialUsage = rawData;
    },
    updatePgmCombinabilityfaCrossTerm(state, rawData) {
      state.pgmCombinability.faCrossTerm = rawData;
    },

    // 更新侧边栏是否展开参数
    updateSideNavCollapsed(state, vue) {
      state.sideNavCollapsed = vue;
    },
    updateSideNavKeys(state, rawData) {
      state.sideNavKeys = rawData;
    },
    updatePgmInsetCol(state, rawData) {
      state.pgmInsetCol = rawData;
    },
    // 记录跳转到登录页前的路由信息
    updateLoginForward(state, loginForward) {
      state.loginForward = loginForward;
    },
    updateCustomerSelected(state, rawData) {
      state.customerSelected = rawData;
    },
    updateMake(state, rawData) {
      state.make = rawData;
    },
    addKeepAliveComponentName(state, componentName) {
      if (state.keepAliveComponentNames.indexOf(componentName) === -1) {
        state.keepAliveComponentNames.push(componentName);
      }
    },
    removeKeepAliveComponentName(state, componentName) {
      const index = state.keepAliveComponentNames.indexOf(componentName);
      if (index !== -1) {
        state.keepAliveComponentNames.splice(index, 1);
      }
    },
    addProgramHomeParams(state, data) {
      state.programHomeParams = data;
    },
    removeProgramHomeParams(state) {
      state.programHomeParams = null;
    },
    updateCombinabilityResetKey(state) {
      state.combinabilityResetKey = new Date().getTime().toString();
    },
    updateShowScrollContent(state, data) {
      state.showScrollContent = data;
    },
    updateUserClosedScrollContent(state, data) {
      state.userClosedScrollContent = data;
    },
    updateLastScrollEndTime(state, data) {
      state.lastScrollEndTime = data;
    },
  },
  actions: {
    updateAuthorization({ dispatch }, params) {
      return getToken(params);
    },
    updateUser({ commit }) {
      return new Promise((resolve, reject) => {
        findCurrentUser()
          .then((res) => {
            const local =
              res.legalEntity.entityCode === 'Dealer' || res.legalEntity.entityCode === 'Dealer Group' ? 'zh' : 'en';
            store.state.lang = local;
            store.commit('updateLang', local);
            sessionStorage.setItem('getLocale', local);
            i18n.global.locale = local;
            commit('updateUser', res);
            resolve(res);
          })
          .catch((e) => {
            commit('updateAuthorization', '');
            store.state.user.usrnm = '';
            reject();
          });
      });
    },
    updateLogout() {
      return new Promise((resolve, reject) => {
        ssoLogout()
          .then((res: string) => {
            // 获取重定向地址
            store.commit('updateAuthorization', '');
            resolve(true);
            window.location.href = res;
          })
          .catch(() => {
            reject();
          });
      });
    },
    authAndUser({ dispatch }, permissionCode) {
      return dispatch('updateAuthorization', permissionCode).then((res) => {
        if (res.code != 0) {
          message.error(res.message);
          // 用户不存在或openId认证失败
          // 请求这个接口退出
          return dispatch('updateLogout');
        } else {
          return dispatch('updateUser');
        }
      });
    },
    updateCustomerSelected({ commit }) {
      /**
       * 更换接口和获取节点
       * */

      const params = {
        buId: store.state.pgmData.businessUnit.selected,
        offerTypes: Object.keys(store.state.pgmData.offer),
        programId: store.state.pgmData.programId
      };
      getDesignSetting(params).then((res) => {
        commit('updateCustomerSelected', res.customer);
      });
    }
  },
  modules: {
    approve,
    claim,
    payment,
    approvalChain,
    spotChecking,
    finance,
    salesReport,
    localRegistration
  },
  // plugins: process.env.NODE_ENV !== 'production' ? [vuexLocal.plugin, createLogger() ] : [vuexLocal.plugin]
  plugins: [vuexLocal.plugin]
});
export default store;
